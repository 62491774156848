<template>
	 <main>
        <HeaderTab :title="$t('monte.planning')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <keep-alive>
                            <router-view></router-view>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: 'PlanningMonte',
		mixins: [Navigation],
		data () {
			return {
				param_tab: '',
                actual_tab: 'planningMain',
                default_tab: 'planningMain',
                tabsToReload: [],
			}
		},

		mounted() {
			this.param_tab = this.$route.params.actual_tab
		},

		methods: {

		},

		computed:{
            swiper_tabs() {
                return [{
                        id: 'planningMain',
                        label: 'monte.planning_monte',
                        active: false,
                        href: 'planningMain'
                    },
                    {
                        id: 'planningMonte',
                        label: 'monte.planning_ia',
                        active: false,
                        href: 'planningIA',
                    },
                ]
            },
        },

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}

</script>